.bacheca-form {
  background-color: $black;
  border-radius: $border-radius;
  padding: 0 10px 20px 10px;

  form {
    position: relative;
    color: $light;

    margin-top: 40px;

    .step {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 0;
      right: 0;

      opacity: 0;

      transition-duration: 0.3s;
      z-index: -1;

      &.active {
        opacity: 1;
        z-index: 1;
        position: unset;
        width: 100%;
      }
    }

    .save-button {
      max-width: 300px;
    }
  }

  $dim: 175px;

  .cover-img-container {
    width: 100%;
    max-width: 500px;
    height: $dim;
    position: relative;

    input {
      display: none;
    }

    .cover {
      height: 100%;
      width: 100%;

      background-color: $light;

      border-radius: $border-radius;

      .cover-image-loading {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 300;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: $border-radius;
      }

      &:hover {
        &::after {
          content: attr(data-text);
          border-radius: $border-radius;

          cursor: pointer;

          position: absolute;

          height: 100%;
          width: 100%;
          display: flex;
          padding-top: $dim / 4;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.6);

          font-weight: bold;
          color: white;
        }
      }
    }
  }

  .profile-img-container {
    width: $dim;
    height: $dim;
    max-width: 100%;
    min-height: $dim;

    position: relative;

    z-index: 400;

    input {
      display: none;
    }

    .profile-image-loading {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 450;
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 100%;
    }

    .avatar {
      cursor: pointer;
      border-radius: 100%;
      height: 100%;
      width: 100%;
      background-color: $light;

      position: relative;

      &:hover {
        &::after {
          content: attr(data-text);
          border-radius: 100%;

          cursor: pointer;

          position: absolute;

          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.6);

          font-weight: bold;
          color: white;
        }
      }
    }
  }

  .preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $light;

    $img-dim: 150px;

    .preview-cover-image {
      background-color: $light;
      height: $img-dim;
      width: 100%;
      max-width: 500px;

      border-radius: $border-radius;
    }

    .preview-profile-image {
      background-color: $light;
      border-radius: 100%;
      border-top: 0.5px solid $muted;
      height: $img-dim;
      width: $img-dim;

      margin-top: -$img-dim / 2;
    }
  }
}
