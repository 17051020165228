textarea {
  border-radius: $border-radius;
  border: 1px solid $black;
  background-color: $background;

  min-height: 50px;
  max-height: 130px;

  padding: 5px 20px;

  &:focus {
    outline: none !important;
    border: 2px solid $black;
  }

  &:disabled {
    opacity: 0.7;
  }
}
