.content-drop {
  .column:nth-child(even) {
    margin-top: 50px;
  }

  .content-block {
    margin: 40px 15px;
    width: 90vw;
    @include media-breakpoint-up(sm) {
      width: 300px;

      transition-duration: 0.3s;
      &:hover {
        scale: 1.05;
      }
    }

    .image {
      position: relative;
      border-radius: $border-radius;
      height: attr(height);
      width: 100%;

      .content-loader {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: $border-radius;
      }

      .content-metadata {
        position: absolute;
        width: 100%;
        bottom: 0;
        display: flex;

        justify-content: space-between;
        align-items: center;

        color: $light;
        border-radius: $border-radius;
        padding: 5px;

        &.approval {
          background-color: $primary;
        }
        &.show {
          background-color: $secondary;
        }
      }

      .content-settings {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }

    .text {
      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
