.comments-section {
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: 25px;

  .comments {
    max-height: 200px;
    overflow-y: hidden;
    &.expanded {
      overflow-y: scroll;
      max-height: 100vh;
    }

    .comment {
      display: flex;
      gap: 20px;

      padding: 15px;
      border-radius: $border-radius;
      &:hover {
        background-color: $light;
      }

      .image {
        $dim: 50px;

        border-radius: 100%;

        height: $dim;
        width: $dim;
        min-width: $dim;
      }
    }
  }

  .expand-button {
    margin-bottom: -40px;
    display: flex;
    justify-content: center;
  }

  .comment-like {
    margin-left: -12px;
  }
}
