.channel-page {
  .cover-image {
    width: 100%;
    height: 20vw;
    max-height: 300px;
    min-height: 150px;
    border-radius: $border-radius;
  }
  .profile-image {
    width: 150px;
    height: 150px;
    min-width: 150px;
    min-height: 150px;
    border-radius: 100%;

    margin-top: -50px;
  }
}
