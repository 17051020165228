.home-how {
  margin-top: 150px;

  .image {
    height: 250px;
    width: 100%;

    border-radius: $border-radius;
  }
}
