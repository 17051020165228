// bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

// BASICS
@import './fonts.scss';
@import './variables.scss';

// PAGES
@import './pages/home';
@import './pages/bachecaform';
@import './pages/channel';
@import './pages/bachecadetail';
@import './pages/contentdetail';
@import './pages/contentform';
@import './pages/partners';

// DOM ELEMENTS
@import './components/dom/button';
@import './components/dom/link';
@import './components/dom/input';
@import './components/dom/scrollbar';
@import './components/dom/selection';
@import './components/dom/form';
@import './components/dom/checkbox';
@import './components/dom/textarea';
@import './components/dom/select';
@import './components/dom/datepicker';
@import './components/dom/dropdownmenu';

// CUSTOM ELEMENTS
@import './components/custom/nav';
@import './components/custom/loader';
@import './components/custom/logo';
@import './components/custom/header';
@import './components/custom/footer';
@import './components/custom/companyinfo';
@import './components/custom/socials';
@import './components/custom/homeabout';
@import './components/custom/homequote';
@import './components/custom/homehow';
@import './components/custom/search';
@import './components/custom/auth';
@import './components/custom/cookiesbanner';
@import './components/custom/profilebox';
@import './components/custom/notificationbox';
@import './components/custom/menubox';
@import './components/custom/bachecacard';
@import './components/custom/custommodal';
@import './components/custom/comments';
@import './components/custom/likes';
@import './components/custom/stepper';
@import './components/custom/contentdrop';
@import './components/custom/sharebox';
@import './components/custom/checkoutform';

body {
  background-color: $background;
  color: $black;
  font-family: $font-primary;
  font-weight: $font-weight-primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-secondary;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100%;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.filter-invert {
  filter: invert(1);
}

.hr {
  width: 100%;
  border-bottom: 1px solid $muted;

  margin: 20px 0;

  &.light {
    border-color: $white;
  }
}
