.bacheca-card-container {
  position: relative;
  width: 100%;

  .metaslot {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 5px 10px;

    display: flex;
    justify-content: right;
    gap: 10px;

    z-index: 99;
    border-radius: $border-radius;
    color: $light;
    &.error {
      background-color: $error;
    }

    &.info {
      background-color: $primary;
    }
  }

  .card-loader {
    position: absolute;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(245, 245, 245, 0.5);

    z-index: 100;

    border-radius: $border-radius;
  }

  .bacheca-card {
    $dimension: 250px;

    width: 100%;

    display: flex;
    flex-direction: column;

    border-radius: $border-radius;

    cursor: pointer;

    background-color: $light;

    .options {
      position: absolute;
      right: 15px;
      top: 10px;

      z-index: 99;

      @include media-breakpoint-down(md) {
        background-color: $primary;
        img {
          filter: invert(1);
        }
      }
    }

    @include media-breakpoint-up(md) {
      gap: 20px;
      height: $dimension;
      flex-direction: row;
      max-height: $dimension;
      overflow: hidden;

      background-color: unset;
      background-color: $light;
    }

    .info {
      position: relative;
      display: flex;
      flex-direction: column;

      padding: 10px;

      @include media-breakpoint-up(md) {
        padding: 25px 25px 25px 0;
      }

      .bio {
        margin-top: 10px;
        overflow: hidden;
        text-align: justify;
      }

      .stats {
        margin-top: auto;
        display: flex;
        gap: 20px;

        .slot {
          display: flex;
          align-items: center;
          gap: 5px;

          img {
            height: 20px;
          }
        }
      }
    }

    .img {
      height: $dimension;
      min-height: $dimension;
      min-width: $dimension;

      @include media-breakpoint-up(md) {
        width: $dimension;
        width: $dimension * 1.5;
        min-width: $dimension * 1.5;
      }

      border-radius: $border-radius;
    }
  }
}
