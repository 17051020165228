.home-quote {
  background-color: $dark;
  position: relative;

  &::after,
  &::before {
    content: '';
    position: absolute;

    height: 50px;
    width: 50px;

    border-radius: 100%;

    background-color: $dark;

    left: 50%;
    transform: translateX(-50%);
  }

  &::after {
    top: -20px;
  }

  &::before {
    bottom: -20px;
  }
}
