.auth-box {
  .auth-toggle {
    background-color: $light;
    padding: 5px;
    height: min-content;
    display: flex;

    width: 85%;

    border-radius: $border-radius;

    > div {
      padding: 15px 30px;
      width: 50%;
      text-align: center;
      cursor: pointer;
      border-radius: $border-radius;

      font-weight: 300;
      text-transform: uppercase;

      background-color: $light;
      color: $black;
      &.selected {
        background-color: $dark;
        color: $light;
      }
    }
  }
}
