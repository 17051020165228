.logo {
  background-color: $black;
  color: $background;

  padding: 8px;
  padding-left: 50px;
  width: max-content;

  clip-path: polygon(0 0, 0 75%, 30px 0, 100% 0, 100% 100%, 0 100%);

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  text-transform: uppercase;

  span {
    margin: -5px 0;
    font-family: $font-secondary;
    user-select: none;
  }

  &.light {
    color: $black;
    background-color: $background;
  }
}
