.content-form-container {
  .content-form {
    background-color: $black;
    border-radius: $border-radius;
    padding: 10px;

    form {
      position: relative;
      color: $light;

      margin-top: 40px;

      .step {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 0;
        right: 0;

        opacity: 0;

        transition-duration: 0.3s;
        z-index: -1;

        &.active {
          opacity: 1;
          z-index: 1;
          position: unset;
          width: 100%;
        }

        .img-preview-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          .img-preview {
            position: relative;
            .delete {
              position: absolute;
              top: 10px;
              right: 10px;

              display: flex;
              align-items: center;
              justify-content: center;

              $dim: 25px;
              width: $dim;
              height: $dim;
              min-width: $dim;

              background-color: $error;
              padding: 5px;
              border-radius: 100%;
            }

            .image {
              border-radius: $border-radius;
              margin: 15px;
              height: 300px;
              width: 300px;
            }
          }
        }

        .img-step-preview {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .image {
            border-radius: $border-radius;
            margin: 10px;
            height: 100px;
            width: 100px;
          }
        }
      }

      .save-button {
        max-width: 300px;
      }
    }
  }
}
