.company-info-container {
  a {

    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;

    &::after {
      content: unset;
    }

    &.light {
      img {
        height: 20px;

        filter: invert(1);
      }
    }
  }
}
