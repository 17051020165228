.stepper {
    $dot-dim: 30px;

    display: flex;
    justify-content: space-between;

    margin-bottom: 40px;

    .step-divider {
      width: 100%;
      @include media-breakpoint-up(md) {
        width: 200%;
      }

      height: 1px;
      margin: ($dot-dim / 2) 5px 0 5px;
      background-color: $muted;

      &:last-child {
        display: none;
      }
    }

    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      width: 100%;
      position: relative;

      .step-dot {
        color: $light;

        height: $dot-dim;
        width: $dot-dim;
        min-width: $dot-dim;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 100%;
        background-color: $primary;
      }

      .step-text {
        color: $muted;
        white-space: nowrap;
        position: absolute;
        bottom: -100%;
      }

      &.active {
        .step-dot {
          background-color: $dark;
        }
        .step-text {
          color: $light;
        }
      }

      &.passed {
        .step-text {
          color: $muted;
        }
      }
    }
  }