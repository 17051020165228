.home {
  .divider {
    margin: 70px 0;
  }

  .main-title {
    position: relative;

    &::after,
    &::before {
      content: '';

      position: absolute;
      top: 50%;
      border-radius: 100%;

      height: 2px;
      width: 50px;
      background-color: $black;
    }

    &::after {
      right: -60px;
    }

    &::before {
      left: -60px;
    }
  }
}
