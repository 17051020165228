.social-link {
  margin: 5px;

  img {
    height: 25px;
    width: 25px;
    border-radius: 30%;
  }

  &::after {
    content: unset;
  }

  &.light {
    filter: invert(1);
  }
}
