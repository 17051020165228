footer {
  background-color: $black;
  color: $light;

  .footer-container {
    padding: 50px 0 25px 0;

    .nav-link {
      margin-bottom: 20px;
    }
  }
}
