.bacheca-detail {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  .bacheca {
    width: 100%;

    height: max-content;

    gap: 20px;

    border-radius: $border-radius;
    // box-shadow: $box-shadow;

    overflow: hidden;

    .cover {
      width: 100%;
      // @include media-breakpoint-up(md) {
      //   width: 50%;
      // }

      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .profile {
        border: 1px solid $black;
        box-shadow: $box-shadow;

        height: 200px;
        width: 200px;
        border-radius: 100%;
      }
    }
    .details {
      padding: 20px;
      // @include media-breakpoint-up(md) {
      //   padding: 15px 0;
      //   width: 50%;
      // }

      .creator {
        display: flex;
        align-items: center;
        gap: 15px;

        .image {
          height: 50px;
          width: 50px;
          border-radius: 100%;
        }
      }
    }
  }

  .comments {
    width: 100%;
    max-width: 1000px;
  }
}
