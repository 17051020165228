.checkout-form {
  background-color: $light;
  border-radius: $border-radius;

  margin-top: 25px;

  padding: 15px;

  .input {
    margin: 20px 0;
    padding: 17px 20px;
    border: 1px solid $dark;
    border-radius: $border-radius;
  }
}
