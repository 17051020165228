.likes-container {
  width: 60px;
  .likes {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;

    border-radius: $border-radius;
    padding: 5px 0;

    &.active {
      background-color: $primary;
      span,
      img {
        filter: invert(1);
      }
    }

    img {
      height: 20px;
    }
  }
}
