.share-box-container {
  position: relative;

  .icon-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &::after {
      content: '';
      z-index: -1;
      position: absolute;
      height: 0;
      width: 0;
      background-color: $primary;
      border-radius: 100%;
      opacity: 0.7;

      transition-duration: 0.3s;
    }

    &:hover {
      &::after {
        height: 100%;
        width: 100%;
      }
    }

    img {
      padding: 10px;
      height: 45px;
      width: 45px;
    }
  }

  .close-btn {
    position: absolute;
    top: 5px;
    left: 15px;
  }

  .share-box {
    background-color: $white;
    padding: 25px;
    border-radius: $border-radius;

    position: absolute;
    bottom: -250%;
    left: 0;

    transform: translateX(-50%);

    .input-box {
      display: flex;
      gap: 5px;
      align-items: center;
    }
  }
}
