.custom-button {
  background-color: $dark;
  text-transform: uppercase !important;
  border: none;
  color: $light;
  padding: 15px 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: $border-radius !important;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  transition-duration: 0.3s;
  &:hover {
    transform: scale(1.05);
  }

  .metadata {
    position: absolute;
    right: 10px;
    font-weight: lighter;

    .loader {
      margin-top: 5px;
      right: -20px;
    }
  }

  &.light {
    background-color: $light;
    color: $dark;
  }

  &.error {
    background-color: $error;
    color: $light;
  }

  &.small {
    padding: 6px 30px;
    font-size: 14px;
    max-height: 30px;

    .loader {
      right: -35px;
    }
  }

  &.secondary {
    background-color: $primary;
  }

  &:disabled {
    cursor: default;
    opacity: 0.75;
    &:hover {
      transform: scale(1);
    }
  }
}
