.content-detail {
  .image-carousel {
    display: flex;

    .images-preview {
      .image-small {
        height: 70px;
        width: 70px;
        margin: 10px;

        border-radius: $border-radius;

        &.selected {
          border: 3px solid $dark;
        }
      }
    }

    .image {
      height: 500px;
      width: 500px;
      border-radius: $border-radius;
      position: relative;

      .navigator {
        position: absolute;
        top: 50%;
        width: 100%;
        display: flex;
        padding: 0 10px;
        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          width: 30px;
          height: 30px;
          background-color: rgba(128, 128, 128, 0.7);
          color: white;
          user-select: none;
        }
      }
    }
  }

  .info {
    .creator {
      display: flex;
      align-items: center;
      gap: 15px;

      .image {
        height: 50px;
        width: 50px;
        border-radius: 100%;
      }
    }
  }
}
