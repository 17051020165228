header {
  margin-top: 50px;

  .photos-container {
    display: flex;
    width: 100vw;
    align-items: center;
    justify-content: center;

    .img-col {
      &:nth-child(odd) {
        margin-top: 150px;
      }

      $dim: 200px;

      width: $dim;
      min-width: $dim;
      height: $dim * 2;
      margin: 0 10px;

      .img {
        width: 100%;
        height: 50%;

        margin: 10px 0;

        border-radius: $border-radius;

        position: relative;

        &::before {
          content: '';
          position: absolute;
          height: 100%;
          width: 100%;

          border-radius: $border-radius;

          background-color: $primary;
          opacity: .2;
        }
      }
    }
  }
}
