.home-about {
  .slot-container {
    margin-top: 50px;

    .slot {
      padding: 25px;
      margin: -15px 15px;
      border-radius: $border-radius;

      max-width: 450px;

      height: max-content;

      &:nth-child(even) {
        margin-top: 30px;
      }
      &:nth-child(odd) {
        margin-top: -50px;
      }
    }
  }
}
