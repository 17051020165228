form {
  display: flex;
  flex-direction: column;

  .col {
    display: flex;
    flex-direction: column;
  }

  input,
  textarea {
    margin-bottom: 20px;
  }

  label {
    margin-left: 15px;
    font-family: $font-secondary;
  }

  p {
    font-family: $font-secondary;
  }
}
