.notification-box {
  width: 100%;
  
  height: 100%;
  overflow: scroll;

  position: relative;

  .notification {
    margin: 10px;

    border: 1px solid $light;

    &.new {
      background-color: $light;
      color: $black;
    }

    border-radius: $border-radius;
    cursor: pointer;

    transition-duration: 0.1s;

    &:hover {
      transform: scale(1.02);
    }

    padding: 25px 0;

    p {
      margin: 0;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.title {
        font-weight: bold;
      }
    }
  }
}
