// COLORS

$background: #ffffff;
$black: #1d1d1d;
$white: #fff;
$muted: gray;

$accent: #d5b9b2ff;
$light: #ece2d0ff;
$secondary: #bfb5afff;
$primary: #a26769ff;
$dark: #582c4dff;
$error: #e63946;

// OTHERS

$border-radius: 25px;
$box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.4);
