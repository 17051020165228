.custom-dropdown-menu-container {
  padding: 5px;
  cursor: pointer;
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px;
  width: 30px;

  img {
    height: 17px;
    width: 17px;
  }
  &:hover,
  &.hovered {
    background-color: $primary;
    img {
      filter: invert(1);
    }
  }

  .custom-dropdown-menu {
    position: relative;

    .menu {
      position: absolute;

      display: flex;
      flex-direction: column;

      padding: 5px;

      &.down {
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
      }

      &.left {
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
      }

      z-index: 500;

      background-color: $white;
      border-radius: $border-radius;

      box-shadow: $box-shadow;

      .menu-option {
        cursor: pointer;
        padding: 10px 20px;
        border-radius: $border-radius;

        &.red {
          color: $error;
        }

        &:hover {
          background-color: $light;
        }
      }
    }
  }
}
