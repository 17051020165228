nav {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;

  background: $background;

  width: 100vw;

  & > div {
    width: 33%;
  }

  .close-search-icon {
    height: 30px;
    margin-left: 10px;
  }

  .search-icon {
    height: 20px;
    margin-top: 5px;
  }

  .search-overlay {
    position: absolute;
    z-index: 500;
    left: 0;
    top: 0;

    animation: expand-search 0.1s ease-out forwards;

    @keyframes expand-search {
      from {
        width: 0;
      }
      to {
        width: 100vw;
      }
    }

    padding: 0 20px;

    background-color: $background;

    display: flex;
    align-items: center;
    height: 100%;
  }

  .menu-container {
    z-index: 400;
  }

  .shoulder-icon {
    padding: 10px 10px;

    height: 59px;

    position: relative;

    > img,
    .search-icon {
      margin-top: 5px;
      height: 25px;
    }

    &.new {
      position: relative;

      &::after {
        content: '';

        height: 0px;
        width: 0px;

        transition-duration: 0.3s;

        background-color: $primary;
        border-radius: 100%;
        opacity: 0.5;
        z-index: -1;

        position: absolute;

        left: 50%;
        top: 50%;

        transform: translateX(-50%) translateY(-55%);
      }

      &:hover {
        &::after {
          height: 40px;
          width: 40px;
        }
      }
    }

    .notification-number {
      $not-dim: 25px;
      height: $not-dim;
      width: $not-dim;
      right: 0;
      top: 0;
      background-color: $error;
      z-index: 600;
      border-radius: 100%;
      position: absolute;

      display: flex;
      align-items: center;
      justify-content: center;

      color: $light;
      font-weight: bold;
    }

    &.selected {
      background-color: $black;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;

      position: relative;

      $dim: 15px;

      img {
        filter: invert(1);
      }

      @keyframes fade-in {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      .inset {
        &::after,
        &::before {
          content: '';

          height: $dim;
          width: $dim;

          position: absolute;

          bottom: 0;

          background-color: $background;

          border-radius: 100%;

          z-index: 100;

          background-color: rgba(245, 245, 245, 1);

          animation: fade-in 0.2s ease-in forwards;
        }

        &::after {
          left: -$dim;
        }

        &::before {
          right: -$dim;
        }
      }

      &::after,
      &::before {
        content: '';
        background-color: $black;

        position: absolute;
        bottom: -$dim / 2;

        height: $dim;
        width: $dim;

        opacity: 0;
        animation: fade-in 0.2s ease-in 0.05s forwards;
      }

      &::after {
        left: -$dim / 2;
      }

      &::before {
        right: -$dim / 2;
      }
    }
  }

  .shoulder-container {
    z-index: 300;
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;

    animation: bg-fade 0.2s ease-out forwards;

    // overflow: hidden;

    @keyframes bg-fade {
      from {
        background-color: rgba(245, 245, 245, 0);
      }
      to {
        background-color: rgba(245, 245, 245, 0.8);
      }
    }

    .shoulder {
      background-color: $black;
      color: $light;

      position: relative;

      margin-top: 75px;
      height: 100%;

      // overflow: hidden;

      max-width: 500px;
      min-width: 390px;

      animation: expand 0.2s ease-out forwards;

      @keyframes expand {
        from {
          width: 0;
        }
        to {
          width: 100%;
        }
      }

      margin-left: auto;

      border-top-left-radius: $border-radius;

      .shoulder-content {
        padding: 100px 25px;
        height: 100%;

        overflow: scroll;
      }

      .close-search-icon-container {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 500;
        width: 100%;
        background-color: $black;
        border-radius: $border-radius;
        .close-search-icon {
          margin-top: 26px;
          margin-left: 10px;
        }
      }
    }
  }
}
