.custom-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999999;

  display: flex;
  align-items: center;
  justify-content: center;

  .custom-modal {
    background-color: $black;
    padding: 15px;
    width: 100%;
    max-width: 600px;
    overflow: scroll;
    max-height: 99vh;
    color: $light;
    border-radius: $border-radius;

    margin: 5px;

    position: relative;

    .close {
      position: absolute;
      top: 0px;
      left: 10px;
      cursor: pointer;
      padding: 10px;
    }

    .actions {
      display: flex;
      justify-content: flex-end;
    }
  }
}

#modal-container {
  position: fixed;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.5);

  padding: 10px;

  .modal-middle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .modal-content {
      max-width: 1000px;
      max-height: 90svh;

      position: relative;
      overflow: hidden;

      .close-modal {
        position: absolute;
        top: 5px;
        right: 0;
        cursor: pointer;

        font-size: larger;
        padding: 5px 13px;

        z-index: 9999;
      }
    }
  }
}
