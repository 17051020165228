.profile-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  $dim: 175px;

  height: 100%;
  overflow: scroll;

  position: relative;

  .cover-img-container {
    width: 100%;
    height: $dim;
    position: absolute;

    input {
      display: none;
    }

    .cover {
      height: 100%;
      width: 100%;

      background-color: $light;

      border-radius: $border-radius;

      .cover-image-loading {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 300;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: $border-radius;
      }

      &:hover {
        &::after {
          content: attr(data-text);
          border-radius: $border-radius;

          cursor: pointer;

          position: absolute;

          height: 100%;
          width: 100%;
          display: flex;
          padding-top: $dim / 4;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.6);

          font-weight: bold;
          color: white;
        }
      }
    }
  }

  .profile-img-container {
    margin-top: $dim / 2;

    width: $dim;
    height: $dim;
    max-width: 100%;
    min-height: $dim;

    position: relative;

    z-index: 400;

    input {
      display: none;
    }

    .profile-image-loading {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 450;
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 100%;
    }

    .avatar {
      cursor: pointer;
      border-radius: 100%;
      height: 100%;
      width: 100%;
      background-color: $light;

      position: relative;

      &:hover {
        &::after {
          content: attr(data-text);
          border-radius: 100%;

          cursor: pointer;

          position: absolute;

          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(0, 0, 0, 0.6);

          font-weight: bold;
          color: white;
        }
      }
    }
  }
}
