.search-input {
  position: relative;

  display: flex;
  align-items: center;

  .add-on {
    position: absolute;
    right: 20px;
    height: 20px;

    display: flex;
    align-items: center;

    img {
      height: 100%;
    }

    .loader {
      margin-right: -30px;
    }
  }

  .search-box {
    position: absolute;
    top: 110%;
    width: 100%;
    left: 0;

    .results-box {
      width: 100%;

      background-color: $white;
      border-radius: $border-radius;

      box-shadow: $box-shadow;

      .result {
        padding: 5px 25px;
        margin: 5px 0;
        
        border-radius: $border-radius;

        cursor: pointer;

        &:hover {
          background-color: $light;
        }

        .image {
          border-radius: $border-radius;
          height: 50px;
          width: 50px;
          margin-right: 15px;
        }

        .name {
          font-weight: bold;
        }
      }
    }

    .search-more {
      width: 100%;

      background-color: $white;
      border-radius: $border-radius;

      box-shadow: $box-shadow;

      display: flex;
      justify-content: center;

      padding: 15px 0;

      gap: 10px;

      cursor: pointer;

      &:hover {
        background-color: $light;
      }
      img {
        height: 25px;
      }
    }
  }
}
