.menu-box {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    margin-bottom: 100px;
    a {
      font-size: x-large;
      margin: 25px 0;
      text-align: center;
      width: 100%;
    }
  }
}
