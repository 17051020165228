#partners-page {
  .goal {
    border-radius: $border-radius;
    padding: 50px 25px;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: $dark;
    color: $light;
  }
}
