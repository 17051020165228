.cookies-banner {
  position: fixed;
  padding: 15px;
  width: 100vw;
  bottom: 0;
  left: 0;
  background-color: $dark;

  color: $light;
}
